:root {
  --appear: appear 0.5s linear 1 forwards;
  --appear-delayed: appear 0.5s linear 0.5s 1 both;
  --disappear: disappear 0.4s linear 1 forwards;
}

@keyframes appear {
  0%  {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes disappear {
  0%  {transform: scale(1);}
  100% {transform: scale(0);}
}